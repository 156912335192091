import { Link } from "components/core/link"
import { SEO } from "components/core/seo"
import { BASE_URL } from "lib/utils"
import React, { FC } from "react"

export const Error404: FC = () => (
  <div className="bg-white dark:bg-gray-900">
    <div className="px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <SEO
        title="Not found"
        description="Frontend Toolkit offers customizable dashboards for your recurring Frontend tasks. Base64 encoder/decoder, SVG optimizations, SVG to JSX and many more!"
        ogImage={{
          title: "Not found",
          url: `${BASE_URL}/404`,
        }}
      />
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:dark:border-gray-800 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link to="/dashboard" variant="primary">
                Go to Dashboard
              </Link>
              <Link to="/" variant="secondary">
                Go to Homepage
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
)
